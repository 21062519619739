import {
    ProductAvailabilityStatus
} from '~/types';


export function useProductAvailabilityStatus(
    status: Nilish<ProductAvailabilityStatus>
) {

    const statusText = computed<Nilish<string>>(() => {
        switch (status) {
            case ProductAvailabilityStatus.Preorder:
                return 'Preorder';
            case ProductAvailabilityStatus.ComingSoon:
                return 'Coming Soon';
            case ProductAvailabilityStatus.Backorder:
                return 'Backorder';
            case ProductAvailabilityStatus.SoldOut:
                return 'Sold Out';
            case ProductAvailabilityStatus.Discontinued:
                return 'Discontinued'
        }

        return;
    });

    const canShowEstShipDate = computed<boolean>(() => {
        switch (status) {
            case ProductAvailabilityStatus.Preorder:
            case ProductAvailabilityStatus.ComingSoon:
            case ProductAvailabilityStatus.Backorder:
                return true;
        }

        return false;
    });

    return {
        statusText,
        canShowEstShipDate
    };
}
